<template>
  <div class="">
    <PageTitle title="" btn="新增" :hideBtn="hideCreateBtn" @btnClick="modal.edit = true" />
    <BaseTable v-loading="loading" :data="displayData">
      <BaseElTableColumn label="名稱" prop="name" align="center" />
      <BaseElTableColumn label="單位" prop="unitName" align="center" />
      <BaseElTableColumn label="有效日期" prop="displayAnnualSettlementDate" align="center" />
      <BaseElTableColumn label="到期通知發送天數" prop="daysDiffExpirationNotify" align="center" />

      <BaseElTableColumn label="操作" fixed="right" width="110" align="center">
        <template slot-scope="scope">
          <TableEditBtnGroup
            hideDelete
            @edit="onRowEdit(scope.row)"
          />
        </template>
      </BaseElTableColumn>
    </BaseTable>

    <EditPointModal
      v-if="modal.edit"
      :data="selectRow"
      @close="onCloseEdit"
      @refresh="getShopPointList"
    />
  </div>
</template>

<script>
import { computed, defineComponent, onBeforeMount, reactive, ref } from 'vue'
import EditPointModal from './EditPointModal.vue'
import { GetShopPointList } from '@/api/shopPoint'
import { useShop } from '@/use/shop'
import { map, get } from 'lodash'

export default defineComponent({
  name: 'PointList',
  components: { EditPointModal },
  setup (props) {
    const { shopId } = useShop()
    const tableData = ref([])
    const loading = ref(false)
    const modal = reactive({
      edit: false,
    })

    const hideCreateBtn = computed(() => {
      return tableData.value.length >= 2
    })

    const selectRow = ref(null)

    const displayData = computed(() => {
      return map(tableData.value, (item) => {
        return {
          ...item,
          displayAnnualSettlementDate: get(item, 'annualSettlementDate') || '-',
        }
      })
    })

    const onRowEdit = (data) => {
      selectRow.value = data
      modal.edit = true
    }

    const getShopPointList = async () => {
      if (loading.value) return
      loading.value = true
      const [res, err] = await GetShopPointList({
        shopId: shopId.value,
      })
      loading.value = false
      if (err) {
        window.$message.error(err)
        return
      }
      tableData.value = res
    }

    const onCloseEdit = () => {
      modal.edit = false
      selectRow.value = null
    }

    onBeforeMount(async () => {
      await getShopPointList()
    })

    return {
      displayData,
      modal,
      selectRow,
      onRowEdit,
      onCloseEdit,
      loading,
      getShopPointList,
      hideCreateBtn,
    }
  },
})
</script>

<style lang="postcss" scoped>

</style>
