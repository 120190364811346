<template>
  <BaseDialog
    title="編輯自定義名稱"
    width="560px"
    :btn1-disabled="loading"
    @close="$emit('close')"
    @confirm="onConfirm"
  >
    <div>
      <BaseElForm
        ref="formRef"
        :model="formData"
        :rules="formRules"
        label-position="left"
        label-width="120px"
      >
        <BaseElFormItem label="點數兌換中心" prop="pointExchangeDisplayName" testName="formData_pointExchangeDisplayName">
          <div class="flex items-center" style="gap: 8px">
            <ElInputWrapper style="width: 150px">
              <BaseElInput v-model="formData.pointExchangeDisplayName" show-word-limit :maxlength="5" />
            </ElInputWrapper>
            <span>兌換中心</span>
          </div>
        </BaseElFormItem>
        <BaseElFormItem label="點數兌換紀錄" prop="pointExchangeRecordDisplayName" testName="formData_pointExchangeRecordDisplayName">
          <div class="flex items-center" style="gap: 8px">
            <ElInputWrapper style="width: 150px">
              <BaseElInput v-model="formData.pointExchangeRecordDisplayName" show-word-limit :maxlength="5" />
            </ElInputWrapper>
            <span>兌換紀錄</span>
          </div>
        </BaseElFormItem>
      </BaseElForm>
    </div>
  </BaseDialog>
</template>

<script>
import { defineComponent, computed, onBeforeMount } from 'vue'
import BaseDialog from '@/components/Dialog/BaseDialog.vue'
import ElInputWrapper from '@/components/ElInputWrapper.vue'
import { lengthRules, noEmptyRules } from '@/validation'
import { UpdateMemberStorePointConfig } from '@/api/memberShop'
import { useBaseForm } from '@/use/useForm'
import { useShop } from '@/use/shop'
import notifyMessage from '@/config/notifyMessage'
import { get } from 'lodash'

export default defineComponent({
  name: 'PointNameEditModal',
  components: {
    BaseDialog,
    ElInputWrapper,
  },
  props: {
    configData: {
      type: Object,
      default: () => ({}),
    },
  },
  setup (props, { emit }) {
    const { shopId } = useShop()
    const { formRef, formData, initFormData, checkForm, loading } = useBaseForm()
    initFormData({
      pointExchangeDisplayName: '點數',
      pointExchangeRecordDisplayName: '點數',
    })

    const formRules = computed(() => {
      const rules = {
        pointExchangeDisplayName: [lengthRules(1, 5), noEmptyRules()],
        pointExchangeRecordDisplayName: [lengthRules(1, 5), noEmptyRules()],
      }
      return rules
    })

    const onConfirm = async () => {
      if (loading.value) return
      loading.value = true
      const valid = await checkForm(formRef.value)
      if (!valid) {
        loading.value = false
        return
      }
      const [, err] = await UpdateMemberStorePointConfig({
        shopId: shopId.value,
        pointExchangeDisplayName: formData.pointExchangeDisplayName,
        pointExchangeRecordDisplayName: formData.pointExchangeRecordDisplayName,
      })
      loading.value = false
      if (err) {
        window.$message.error(err)
        return
      }
      window.$message.success(notifyMessage.updateSuccess)
      emit('refresh')
      emit('close')
    }

    onBeforeMount(() => {
      formData.pointExchangeDisplayName = get(props.configData, 'pointExchangeDisplayName')
      formData.pointExchangeRecordDisplayName = get(props.configData, 'pointExchangeRecordDisplayName')
    })

    return {
      formRef,
      formData,
      formRules,
      loading,
      onConfirm,
    }
  },
})
</script>
