<template>
  <section v-loading="loading" class="section-block">
    <SectionTitle title="自定義名稱設定" @edit="modal.edit = true" />
    <div>
      <BaseElForm label-position="left" label-width="250px">
        <BaseElFormItem label="點數兌換中心" prop="pointExchangeDisplayName">
          <div class="secret-text collapse">
            <span>{{ displayData.pointExchangeDisplayName || '點數' }}兌換中心</span>
          </div>
        </BaseElFormItem>
        <BaseElFormItem label="點數兌換紀錄" prop="pointExchangeRecordDisplayName">
          <div class="secret-text collapse">
            <span>{{ displayData.pointExchangeRecordDisplayName || '點數' }}兌換紀錄</span>
          </div>
        </BaseElFormItem>
      </BaseElForm>
    </div>
    <PointNameEditModal
      v-if="modal.edit"
      :configData="displayData"
      @close="modal.edit = false"
      @refresh="getMemberStorePointConfig"
    />
  </section>
</template>

<script>
import { defineComponent, reactive, ref, computed, onMounted } from 'vue'
import PointNameEditModal from './PointNameEditModal.vue'
import { GetMemberStorePointConfig } from '@/api/memberShop'
import store from '@/store'
import { get } from 'lodash'

export default defineComponent({
  name: 'PointNameSetting',
  components: {
    PointNameEditModal,
  },
  // emits: ['refresh'],
  setup () {
    const loading = ref(null)
    const shopId = computed(() => store.getters.shop)
    const configData = ref({})
    const formData = reactive({
      pointExchangeDisplayName: '點數',
      pointExchangeRecordDisplayName: '點數',
    })
    const modal = reactive({
      edit: false,
    })

    const getMemberStorePointConfig = async () => {
      loading.value = true
      const [res, err] = await GetMemberStorePointConfig({ shopId: shopId.value })
      loading.value = false
      if (err) {
        window.$message.error(err)
        return
      }
      configData.value = res
      formData.pointExchangeDisplayName = res.pointExchangeDisplayName
      formData.pointExchangeRecordDisplayName = res.pointExchangeRecordDisplayName
    }

    const displayData = computed(() => {
      return {
        pointExchangeDisplayName: get(configData.value, 'pointExchangeDisplayName', '點數'),
        pointExchangeRecordDisplayName: get(configData.value, 'pointExchangeRecordDisplayName', '點數'),
      }
    })

    onMounted(() => {
      getMemberStorePointConfig()
    })
    return {
      modal,
      loading,
      displayData,
      getMemberStorePointConfig,
    }
  },
})
</script>
